<template>
  <b-nav-item-dropdown toggle-class="pt-1 pb-0" menu-class="text-nowrap text-left text-sm-right scrollable-menu" right lazy @shown="focus">
    <template #button-content>
      <Icon type="t" isNav :isButton="false" /> {{ $t('nav.tz') }}
    </template>
    <b-dd-item-button
        v-for="(tz) in tzs"
        :key="`tz-${tz.v}`" :ref="`tz-${tz.v}`" :value="tz.v" @click="set(tz.v)" :active="tz.v===settTz">
      {{ tz.t }}
    </b-dd-item-button>
  </b-nav-item-dropdown>
</template>

<script>
import Icon from '@/components/Icon';
import tzSet from '@/components/data/Timezones';

export default {
  data() {
    return {
      tzs: tzSet
    }
  },
  computed: {
    settTz() {
      return this.$store.state.settings.timezone;
    },
  },
  methods: {
    set(tz) {
      if (this.settTz !== tz) {
          this.$store.commit('setTimezone', tz);
      }
    },
    focus() {
      this.$refs['tz-'+this.settTz][0].$el.childNodes[0].focus()
    },
  },
  components: {
    Icon,
  },
}
</script>
